import { useRouter } from "next/router"

import { useAppSelector } from "@redux/hooks"

interface UseOnPageSeoArgs {
    variables?: Record<string, string>
}

const useOnPageSeo = ({ variables }: UseOnPageSeoArgs) => {
    const router = useRouter()
    const { data: config } = useAppSelector((state) => state.onPageSeo)

    const getSpecificPage = () => {
        const data = config?.find((item) => item.url === router.asPath)

        if (!data) {
            if (router.pathname.includes("[") && router.pathname.includes("]")) {
                const mainPath = router.pathname.replace(/\/\[.*\]/g, "")
                return config?.find((item) => item.url === mainPath)
            }
        }

        return data
    }

    let data = getSpecificPage()

    if (data && variables) {
        Object.keys(variables).forEach((key) => {
            if (data) {
                data = {
                    ...data,
                    title: {
                        id: data.title?.id?.replace(`{${key}}`, variables[key]),
                        en: data.title?.en?.replace(`{${key}}`, variables[key])
                    },
                    metaDescription: {
                        id: data.metaDescription?.id?.replace(`{${key}}`, variables[key]),
                        en: data.metaDescription?.en?.replace(`{${key}}`, variables[key])
                    }
                }
            }
        })
    }

    return data
}

export default useOnPageSeo
