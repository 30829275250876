import { useMemo } from "react"
import NextHead from "next/head"
import { useRouter } from "next/router"

import { PUBLIC_URL } from "@config/config"
import { defaultLanguage, languages } from "@config/i18n"
import useOnPageSeo from "@hooks/useOnPageSeo"

const siteURL = `${PUBLIC_URL || "https://reku.id"}`

interface HeadProps extends React.PropsWithChildren<{}> {
    title?: string
    titleSuffix?: string
    description?: string
    image?: string
    variables?: Record<string, string>
}

const Head: React.FC<HeadProps> = ({
    title: titleProp,
    titleSuffix,
    description: descriptionProp,
    children,
    image,
    variables
}: HeadProps) => {
    const router = useRouter()
    const onPageSeo = useOnPageSeo({ variables })

    const locale = (router.locale ?? defaultLanguage) as "en" | "id"

    const title = useMemo(() => {
        if (onPageSeo?.title[locale]) {
            return onPageSeo.title[locale]
        }

        if (titleSuffix) {
            return `${titleProp} ${titleSuffix}`
        }

        return `${titleProp} - PasarFx`
    }, [onPageSeo?.title, locale, titleProp, titleSuffix])

    const description = useMemo(() => {
        if (onPageSeo?.metaDescription[locale]) {
            return onPageSeo.metaDescription[locale]
        }

        return descriptionProp
    }, [onPageSeo?.metaDescription, locale, descriptionProp])

    const renderedRobotsMeta = useMemo(() => {
        const robots = []

        if (onPageSeo?.noIndex) {
            robots.push("noindex")
        }

        if (onPageSeo?.noFollow) {
            robots.push("nofollow")
        }

        if (robots.length === 0) {
            return null
        }

        return <meta name='robots' content={robots.join(", ")} />
    }, [onPageSeo?.noIndex, onPageSeo?.noFollow])

    const renderedCanonical = useMemo(() => {
        if (onPageSeo?.canonical_url) {
            if (!onPageSeo.canonical_url.includes("http")) {
                return <link rel='canonical' href={`${siteURL}${onPageSeo.canonical_url}`} />
            }

            return <link rel='canonical' href={onPageSeo.canonical_url} />
        }

        return null
    }, [onPageSeo?.canonical_url])

    const renderedHrefLang = languages.map((lang) => {
        const subPath = lang.code !== defaultLanguage ? `/${lang.code}` : ""
        const href = `${siteURL}${subPath}${router.asPath}`

        return <link key={lang.code} rel='alternate' href={href} hrefLang={lang.code} />
    })

    return (
        <NextHead>
            <meta
                name='viewport'
                property='viewport'
                content='width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1, minimum-scale=1, user-scalable=no'
            />

            <meta name='description' property='description' content={description} key='description' />

            <meta name='og:type' property='og:type' content='website' key='og:type' />
            <meta name='og:site_name' property='og:site_name' content='PasarFx' key='og:site_name' />
            <meta name='og:image' property='og:image' content={image} key='og:image' />
            <meta name='og:title' property='og:title' content={title} key='og:title' />
            <meta name='og:description' property='og:description' content={description} key='og:description' />

            <meta name='twitter:card' property='twitter:card' content='summary' key='twitter:card' />
            <meta name='twitter:site' property='twitter:site' content='PasarFx' key='twitter:site' />
            <meta name='twitter:image' property='twitter:image' content={image} key='twitter:image' />
            <meta name='twitter:image:src' property='twitter:image:src' content={image} key='twitter:image:src' />
            <meta name='twitter:title' property='twitter:title' content={title} key='twitter:title' />
            <meta
                name='twitter:description'
                property='twitter:description'
                content={description}
                key='twitter:description'
            />

            <link rel='apple-touch-icon' sizes='57x57' href='/pasarfx.png' />
            <link rel='apple-touch-icon' sizes='60x60' href='/pasarfx.png' />
            <link rel='apple-touch-icon' sizes='72x72' href='/pasarfx.png' />
            <link rel='apple-touch-icon' sizes='76x76' href='/pasarfx.png' />
            <link rel='apple-touch-icon' sizes='114x114' href='/pasarfx.png' />
            <link rel='apple-touch-icon' sizes='120x120' href='/pasarfx.png' />
            <link rel='apple-touch-icon' sizes='144x144' href='/pasarfx.png' />
            <link rel='apple-touch-icon' sizes='152x152' href='/pasarfx.png' />
            <link rel='apple-touch-icon' sizes='180x180' href='/pasarfx.png' />
            <link rel='icon' type='image/png' sizes='192x192' href='/pasarfx.png' />
            <link rel='icon' type='image/png' sizes='32x32' href='/pasarfx.png' />
            <link rel='icon' type='image/png' sizes='96x96' href='/pasarfx.png' />
            <link rel='icon' type='image/png' sizes='16x16' href='/pasarfx.png' />
            <meta name='msapplication-TileColor' content='#ffffff' />
            <meta name='msapplication-TileImage' content='/pasarfx.png' />

            {renderedRobotsMeta}

            {children}

            <title>{title}</title>

            {renderedCanonical}
            {renderedHrefLang}
        </NextHead>
    )
}

Head.defaultProps = {
    title: "Harga Bitcoin & Jual Beli Bitcoin Indonesia",
    description:
        "Jual beli Bitcoin Indonesia, Harga Bitcoin, kripto, dan aset kripto lainnya dengan rupiah dan biaya terendah",
    titleSuffix: "",
    image: "/pasarfx.png",
    variables: undefined
}

export default Head
