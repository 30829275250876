import React from "react"
import dynamic from "next/dynamic"
import tw from "twin.macro"

import Head from "@components/Head"

const Hero = dynamic(() => import("./components/Hero"))
const Surplus = dynamic(() => import("./components/Surplus"))
const Feature = dynamic(() => import("./components/Feature"))
const Market = dynamic(() => import("./components/Market"))
const CTA = dynamic(() => import("./components/CTA"))

const Wrapper = tw.main`
    bg-background dark:bg-dark-background
    flex flex-col
    xl:pt-[76px] pt-0 pb-[17px]
`

const HomeStock = () => {
    return (
        <Wrapper className='reku-new'>
            <Head />
            <Hero />
            <Surplus />
            <Feature />
            <Market />
            <CTA />
        </Wrapper>
    )
}

export default HomeStock
