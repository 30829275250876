import { serverSideTranslations } from "next-i18next/serverSideTranslations"

import Home from "@app/HomeStock"
import { defaultLanguage } from "@config/i18n"

export const getStaticProps = async ({ locale }: { locale: string }) => ({
    props: {
        ...(await serverSideTranslations(locale || defaultLanguage, ["components", "common", "pages", "home"]))
    }
})

export default Home
